import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { Badge, WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { WdxMarkdownToHtmlPipe, WdxSafeHtmlPipe } from '@wdx/shared/utils';

@Component({
    selector: 'wdx-audit-value',
    standalone: true,
    imports: [
        CommonModule,
        WdxBadgeModule,
        WdxMarkdownToHtmlPipe,
        WdxSafeHtmlPipe,
    ],
    templateUrl: './wdx-audit-value.component.html',
})
export class WdxAuditValueComponent {
    @Input() label?: string;
    @Input() subtitle?: string;
    @Input() badge?: Badge;

    @HostBinding('class') class =
        'd-flex flex-column h-100 justify-content-center';
}
