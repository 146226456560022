import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AppStatus,
    DocumentRequirement,
    DocumentRequirementCreate,
    DocumentRequirementType,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class DocumentChecklistService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getDocumentRequirementsCategories(
        entityId: string,
        entityType: SystemEntity,
        data: string[] = []
    ): Observable<DocumentRequirement[]> {
        return this.http.post<DocumentRequirement[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/documentrequirement/${entityType}/${entityId}/categories`,
            data
        );
    }

    addDocumentRequirement(
        caseId: string,
        entityId: string,
        requirementTypeCode: string,
        systemEntity: SystemEntity,
        relatedId?: string,
        relatedEntity?: SystemEntity
    ): Observable<DocumentRequirement> {
        const mainEntity =
            systemEntity === SystemEntity.Case ? 'case' : 'client';
        const relatedEntityType =
            relatedEntity == SystemEntity.ClientProduct
                ? 'clientProduct'
                : 'party';

        return this.http.post(
            `${this.config.getConfiguration().API_BASE}/documentrequirement`,
            {
                requirementType: requirementTypeCode,
                caseId,
                [mainEntity]: { id: entityId },
                ...(relatedId
                    ? { [relatedEntityType]: { id: relatedId } }
                    : {}),
            } as DocumentRequirementCreate
        );
    }

    generateDocumentFromTemplate(
        payload: {
            documentRequirementId: string;
            documentTemplateId: string;
        }[]
    ): Observable<any> {
        return this.http.patch(
            `${
                this.config.getConfiguration().API_BASE
            }/documentrequirement/generate`,
            payload
        );
    }

    approveDocumentRequirement(
        documentRequirementIds: string[],
        approve = true
    ): Observable<any> {
        return this.http.patch(
            `${this.config.getConfiguration().API_BASE}/documentrequirement/${
                approve ? 'approve' : 'reject'
            }`,
            documentRequirementIds
        );
    }

    cancelDocumentRequirement(
        documentRequirementIds: string[]
    ): Observable<any> {
        return this.http.patch(
            `${
                this.config.getConfiguration().API_BASE
            }/documentrequirement/cancel`,
            documentRequirementIds
        );
    }

    deleteDocumentRequirement(documentRequirementId: string): Observable<any> {
        return this.http.delete(
            `${
                this.config.getConfiguration().API_BASE
            }/documentrequirement/${documentRequirementId}`
        );
    }

    createDocumentExternalTask(
        documentRequirementId: string,
        externalUserPartyId: string
    ): Observable<any> {
        return this.http.post(
            `${
                this.config.getConfiguration().API_BASE
            }/externaluser/${externalUserPartyId}/documentrequirement/${documentRequirementId}`,
            documentRequirementId
        );
    }

    sendDocumentRequirement(documentRequirementIds: string[]): Observable<any> {
        return this.http.patch(
            `${
                this.config.getConfiguration().API_BASE
            }/documentrequirement/sent`,
            documentRequirementIds
        );
    }

    getDocumentRequirementTypes(
        app: AppStatus
    ): Observable<DocumentRequirementType[]> {
        let queryParam = '';

        if (app?.case?.id) {
            queryParam = queryParam + `caseId=${app.case.id}`;
        }

        if (app?.client?.id) {
            queryParam =
                queryParam +
                `${queryParam ? '&' : ''}clientId=${app.client.id}`;
        }

        if (app?.id) {
            queryParam = queryParam + `${queryParam ? '&' : ''}appId=${app.id}`;
        }

        if (app?.activities?.length) {
            app?.activities.forEach((activity) => {
                queryParam =
                    queryParam +
                    `${queryParam ? '&' : ''}activityId=${activity.id}`;
            });
        }

        if (queryParam) {
            queryParam = `?${queryParam}`;
        }

        return this.http.get<DocumentRequirementType[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/documentrequirementtype${queryParam}`
        );
    }

    linkDocumentToRequirement(
        documentRequirementId: string,
        documentId: string,
        isLinked = true
    ): Observable<any> {
        if (isLinked) {
            return this.http.patch(
                `${
                    this.config.getConfiguration().API_BASE
                }/documentrequirement/${documentRequirementId}/document/${documentId}`,
                {}
            );
        } else {
            return this.http.delete(
                `${
                    this.config.getConfiguration().API_BASE
                }/documentrequirement/${documentRequirementId}/document/${documentId}`
            );
        }
    }
}
