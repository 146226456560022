import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { newSystemEvent, newSystemSignalrEvent } from './system-events.actions';
import { saveCompletionSummarySuccess } from '@wdx/shared/infrastructure/form-framework';
import * as appActions from '../apps/apps.actions';
import { AllSignalrEvents, SignalrEvent } from '@wdx/shared/utils';
import { mergeMapHubToAction, signalrConnected } from 'ngrx-signalr-core';
import { merge } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class SystemEventsEffects {
    private actions$ = inject(Actions);

    captureEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                saveCompletionSummarySuccess,
                appActions.confirmSuccess,
                SignalrEvent.PartyRoleUpdated
            ),
            map((action) =>
                newSystemEvent({
                    event: { sourceAction: action, timeStamp: Date.now() },
                })
            )
        )
    );

    captureSignalrEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signalrConnected),
            mergeMapHubToAction(({ hub }) => {
                const signalrActions = AllSignalrEvents.map((signalrEvent) =>
                    hub.on<string[]>(signalrEvent).pipe(
                        map((data) => {
                            if (!environment.production) {
                                // eslint-disable-next-line no-restricted-syntax
                                console.debug(
                                    `(${
                                        new Date()
                                            .toTimeString()
                                            .split(' ')?.[0]
                                    }) SignalR Message:`,
                                    signalrEvent,
                                    data
                                );
                            }
                            return newSystemSignalrEvent({
                                event: {
                                    signalrEvent,
                                    timeStamp: Date.now(),
                                    data,
                                },
                            });
                        })
                    )
                );

                return merge(...signalrActions);
            })
        )
    );
}
