import { inject, Injectable } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import {
    EntityPermissionType,
    SystemEntity,
    TeamMember,
} from '@wdx/clmi/api-models';
import { ActionButton } from '../../../../../../models/action-button.model';

import { ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

import { dynamicFormsActions } from '@wdx/shared/infrastructure/form-framework';
import { AssignToEvent } from '../../../../../../models/assign.model';
import { DialogMode } from '../../../../../../models/dialog.model';
import { DialogService } from '../../../../../../services/dialog.service';
import * as rootReducer from '../../../../../../state/_setup/reducers';
import * as assignActions from '../../../../../../state/assign/assign.actions';

import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import {
    Severity,
    TRANSLATION_TOAST_MESSAGE_REMOVE_PRIMARY_TEAM_MEMBER,
    TRANSLATION_TOAST_MESSAGE_TEAM_MEMBER_DELETED_HAS_ERROR,
    TranslationsService,
} from '@wdx/shared/utils';
import {
    ICON_ADD,
    ICON_EDIT,
} from '../../../../../../constants/icons.constants';
import { ActionButtonMode } from '../../../../../../models/action-buttons-mode.model';
import * as teamActions from '../../../../../../state/team/team.actions';
import { TeamMembersTransformService } from '../team-members-transform';
import { TEAM_MEMBERS_STATE } from './team-members.constant';

@Injectable()
export class TeamMembersService {
    membersCount$ = new BehaviorSubject(0);
    teamData$: Observable<any[]>;
    teamMembers$ = new BehaviorSubject(null);
    teamOwner$ = new BehaviorSubject(null);
    actionBtn$ = new BehaviorSubject<ActionButton>(null);
    assignActionBtn$ = new BehaviorSubject<ActionButton>(null);
    assignSuccess$ = new BehaviorSubject<{
        entityType: SystemEntity;
        entityId: string;
        partyId: string;
    }>(null);
    teamMembersIsLoading$: Observable<boolean>;
    addingTeamMembersIsLoading$ = new Subject();
    teamMembersHasError$: Observable<boolean>;
    destroyed$ = new Subject();
    stateData;

    entityId: string;
    systemEntity: SystemEntity;
    teamMemberForm: string;

    private translationsService = inject(TranslationsService);

    constructor(
        private store$: Store<rootReducer.State>,
        private tmtService: TeamMembersTransformService,
        private actionSubject$: ActionsSubject,
        private dialogService: DialogService,
        private toastService: WdxToastService
    ) {}

    getTeamMembers(): void {
        this.stateData = TEAM_MEMBERS_STATE[this.systemEntity];
        this.membersCount$.next(0);

        this.loadTeamMembers();
        this.assignTeamMemberAsOwner();
        this.removeTeamMember();
        this.setActionBtnAddTeamMember();
        this.setAssignActionBtn();
        this.listenToFormChanges();
        this.listenToAssignSuccess();
    }

    listenToFormChanges(): void {
        this.actionSubject$
            .pipe(
                ofType(
                    teamActions.addTeamMemberSuccess,
                    teamActions.addTeamMemberFailure
                ),
                takeUntil(this.destroyed$)
            )
            .subscribe(() => {
                this.addingTeamMembersIsLoading$.next(false);
                this.dispatchTeamMembers();
            });

        this.actionSubject$
            .pipe(
                ofType(
                    dynamicFormsActions.createNonStandardFormDataSuccess,
                    dynamicFormsActions.updateStandardFormDataSuccess,
                    dynamicFormsActions.updateNonStandardFormDataSuccess
                ),
                filter((action: any) => {
                    const formId = action?.formId;

                    return formId === this.teamMemberForm;
                }),
                takeUntil(this.destroyed$)
            )
            .subscribe(() => {
                this.dispatchTeamMembers();
            });
    }

    listenToAssignSuccess(): void {
        this.actionSubject$
            .pipe(
                ofType(assignActions.assignToSuccess),
                takeUntil(this.destroyed$)
            )
            .subscribe((assignData) => {
                this.assignSuccess$.next(assignData);
            });
    }

    setActionBtnAddTeamMember(): void {
        const KEY = TEAM_MEMBERS_STATE[this.systemEntity].getInitialiseKey
            ? TEAM_MEMBERS_STATE[this.systemEntity].getInitialiseKey
            : TEAM_MEMBERS_STATE[this.systemEntity].getDispatchKey;

        this.actionBtn$.next({
            mode: ActionButtonMode.IconButton,
            icon: ICON_ADD.icon,
            formSetup: {
                formId: this.teamMemberForm,
                initialisationParams: {
                    [KEY]: this.entityId,
                },
            },
            permission: EntityPermissionType.ManageRecordAccess,
            operationsSetup: {
                entityType: this.systemEntity,
                entityId: this.entityId,
            },
        });
    }

    setAssignActionBtn(): void {
        this.assignActionBtn$.next({
            mode: ActionButtonMode.IconButton,
            icon: ICON_EDIT.icon,
            permission: EntityPermissionType.ManageRecordAccess,
            operationsSetup: {
                entityType: this.systemEntity,
                entityId: this.entityId,
            },
        });
    }

    onTeams(): void {
        let membersData = [];
        let countOwner = 0;

        this.teamData$
            .pipe(
                filter((res) => Boolean(res?.length)),
                tap((members) => {
                    membersData = [];
                    countOwner = 0;

                    const memberIds = members.map((member) => member.party?.id);
                    const uniqueMemberIds = memberIds.filter(
                        (element, index) => memberIds.indexOf(element) === index
                    );

                    this.membersCount$.next(uniqueMemberIds?.length);
                    this.teamMembers$.next(null);
                    this.teamOwner$.next(null);

                    members?.map((member) => {
                        if (member.isOwner && !countOwner) {
                            countOwner = countOwner + 1;

                            this.teamOwner$.next(
                                this.tmtService.setUpInfoCard(
                                    member,
                                    true,
                                    this.systemEntity
                                )
                            );
                        } else {
                            membersData.push(
                                this.tmtService.partyToTeam(
                                    member,
                                    this.entityId,
                                    this.systemEntity,
                                    EntityPermissionType.ManageRecordAccess,
                                    this.teamMemberForm
                                )
                            );
                        }
                    });
                }),
                takeUntil(this.destroyed$)
            )
            .subscribe((_) => {
                this.teamMembers$.next(membersData);
            });
    }

    loadTeamMembers(): void {
        this.teamData$ = this.store$.select(this.stateData.get, {
            id: this.entityId,
        });

        this.teamMembersIsLoading$ = this.store$.select(
            this.stateData.loading,
            { id: this.entityId }
        );

        this.teamMembersHasError$ = this.store$.select(this.stateData.error, {
            id: this.entityId,
        });

        this.onTeams();
        this.dispatchTeamMembers();
    }

    onAssignTo(assignToEvent: AssignToEvent) {
        this.actionSubject$
            .pipe(ofType(assignActions.assignToSuccess), take(1))
            .subscribe((_) => {
                this.dispatchTeamMembers();
            });

        this.store$.dispatch(assignActions.assignTo(assignToEvent));
    }

    assignTeamMemberAsOwner(): void {
        this.tmtService.assignTeamMemberAsOwner$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((partyId: string) => {
                this.onAssignTo({
                    entityType: this.systemEntity,
                    entityId: this.entityId,
                    partyId,
                });
            });
    }

    removeTeamMember(): void {
        this.actionSubject$
            .pipe(
                ofType(this.stateData.deleteTeamMemberSuccess),
                takeUntil(this.destroyed$)
            )
            .subscribe((_) => {
                this.dispatchTeamMembers();
            });

        this.actionSubject$
            .pipe(
                ofType(this.stateData.deleteTeamMemberFailure),
                takeUntil(this.destroyed$)
            )
            .subscribe(() => {
                this.toastService.show({
                    body: [
                        this.translationsService.getTranslationByKey(
                            TRANSLATION_TOAST_MESSAGE_TEAM_MEMBER_DELETED_HAS_ERROR
                        ),
                    ],
                    severity: Severity.Danger,
                });
            });

        this.tmtService.removeTeamMember$
            .pipe(
                takeUntil(this.destroyed$),
                tap((teamMember: TeamMember) => {
                    if (teamMember?.isPrimaryTeam) {
                        this.toastService.show({
                            body: [
                                this.translationsService.getTranslationByKey(
                                    TRANSLATION_TOAST_MESSAGE_REMOVE_PRIMARY_TEAM_MEMBER
                                ),
                            ],
                            severity: Severity.Danger,
                        });
                    }
                }),
                filter((teamMember: TeamMember) => !teamMember?.isPrimaryTeam)
            )
            .subscribe((teamMember: TeamMember) => {
                this.dialogService.open({
                    dialogMode: DialogMode.RemoveTeamMember,
                    confirmAction: this.stateData.deleteTeamMember({
                        [this.stateData.getDispatchKey]: this.entityId,
                        [this.stateData.deleteTypeKeyProp]: teamMember?.id,
                    }),
                });
            });
    }

    dispatchTeamMembers(): void {
        this.store$.dispatch(
            this.stateData.getDispatch({
                [this.stateData.getDispatchKey]: this.entityId,
            })
        );
    }
}
