<wdx-alert
    *ngIf="pendingChanges?.length || subFormCount"
    data-cy="pending-changes-alert"
    themeColor="warning"
    [allowFlex]="false"
>
    <div class="d-flex justify-content-between">
        <div class="d-flex align-items-start">
            <wdx-icon
                class="me-2"
                data-cy="pending-changes-icon"
                [icon]="ICON_TRIANGLE_EXCLAMATION"
            ></wdx-icon>

            <div>
                <p
                    class="mb-1"
                    data-cy="pending-changes-message"
                    [textContent]="
                        pendingChanges.length
                            ? (pendingChanges.length + subFormCount
                              | i18nPlural : PENDING_CHANGES_MAPPING)
                            : (subFormCount
                              | i18nPlural : PENDING_CHANGES_MAPPING)
                    "
                ></p>

                <div
                    [attr.data-cy]="'pending-change-' + i"
                    *ngFor="let pendingChange of pendingChanges; let i = index"
                    [textContent]="
                        PENDING_TEXT
                            | replace
                                : [
                                      {
                                          value:
                                              pendingChange?.name!
                                              | translateTokenisedString,
                                          target: '{field}'
                                      },
                                      {
                                          value: pendingChange?.originalDisplayValue!,
                                          target: '{original}'
                                      },
                                      {
                                          value: pendingChange?.targetDisplayValue!,
                                          target: '{target}'
                                      }
                                  ]
                    "
                ></div>

                <div
                    [attr.data-cy]="'pending-change-sub-form-' + i"
                    *ngFor="
                        let subForm of pendingChangesSubForm | keyvalue;
                        let i = index;
                        let last = last
                    "
                >
                    {{ subForm.value.label }}:

                    <span class="me-2" *ngIf="subForm.value.updating">
                        <b>{{ subForm.value?.updating }}</b>
                        {{
                            subForm.value?.updating
                                | i18nPlural : PENDING_CHANGES_SUB_FORM
                        }}
                        updated
                    </span>

                    <span class="me-2" *ngIf="subForm.value.adding">
                        <b>{{ subForm.value?.adding }}</b>
                        {{
                            subForm.value?.adding
                                | i18nPlural : PENDING_CHANGES_SUB_FORM
                        }}
                        added
                    </span>

                    <span class="me-2" *ngIf="subForm.value.deleting">
                        <b>{{ subForm.value?.deleting }}</b>
                        {{
                            subForm.value?.deleting
                                | i18nPlural : PENDING_CHANGES_SUB_FORM
                        }}
                        deleted
                    </span>
                </div>
            </div>
        </div>

        <div class="align-self-center" *ngIf="showButton">
            <button
                wdxButton
                data-cy="pending-changes-button"
                (click)="onClick()"
            >
                {{ CHANGE_SUMMARY_BTN | translate }}
            </button>
        </div>
    </div>
</wdx-alert>
