import { DialogMode } from '../models/dialog.model';

export const DIALOG_TITLE_MAP = {
    [DialogMode.CancelCase]: 'Cancel Case',
    [DialogMode.CancelActivity]: 'Cancel Activity',
    [DialogMode.CancelDocumentRequirement]: 'Cancel Document Requirement',
    [DialogMode.DeleteActivity]: 'Delete Activity',
    [DialogMode.RemoveTeamMember]: 'Remove Team Member',
    [DialogMode.DeleteDashboard]: 'Delete Dashboard',
    [DialogMode.DeleteOpportunity]: 'Delete Opportunity',
    [DialogMode.DeleteFiledDocument]: 'Delete Document',
    [DialogMode.Delete]: 'Delete',
    [DialogMode.ShareAppWithClient]: 'Share with Client?',
};
