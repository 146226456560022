import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsService } from '../../services/translations';

@Pipe({
    name: 'translateTokenisedString',
    standalone: true,
})
export class TranslateTokenisedStringPipe implements PipeTransform {
    constructor(private translationsService: TranslationsService) {}

    transform(value: string | undefined): string {
        if (!value) {
            return '';
        }
        return this.translationsService.translateTokenisedString(value);
    }
}
