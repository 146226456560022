import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    Activity,
    ActivityExternalTask,
    ExternalTaskStatusType,
} from '@wdx/clmi/api-models';
import {
    TRANSLATION_STATUS_TAG_CANCELLED,
    TRANSLATION_STATUS_TAG_CANNOT_START,
    TRANSLATION_STATUS_TAG_COMPLETE,
    TRANSLATION_STATUS_TAG_CONFIRMED,
    TRANSLATION_STATUS_TAG_DRAFT,
    TRANSLATION_STATUS_TAG_ENDED,
    TRANSLATION_STATUS_TAG_NOT_STARTED,
    TRANSLATION_STATUS_TAG_OPEN,
    TRANSLATION_STATUS_TAG_OVERDUE,
    TRANSLATION_STATUS_TAG_READY,
    TRANSLATION_STATUS_TAG_RECEIVED,
    TRANSLATION_STATUS_TAG_SENT,
    TRANSLATION_STATUS_TAG_SUBMITTED,
    WdxThemeColor,
} from '@wdx/shared/utils';
import { ToDoService } from '../../services/to-do/to-do.service';

@Component({
    selector: 'clmi-activity-status-tag',
    templateUrl: './activity-status-tag.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityStatusTagComponent {
    @Input() set activity(activity: Activity | ActivityExternalTask) {
        if (activity) {
            this._activity = activity;
            this.label = this.getLabel(activity);
            this.themeColor = this.getThemeColor(activity);
        }
    }

    get activity(): Activity | ActivityExternalTask {
        return this._activity;
    }

    _activity: Activity | ActivityExternalTask;
    label: string;
    themeColor: WdxThemeColor;

    getThemeColor(activity: Activity | ActivityExternalTask): WdxThemeColor {
        if ((activity as ActivityExternalTask).externalStatus) {
            switch ((activity as ActivityExternalTask).externalStatus) {
                case ExternalTaskStatusType.CannotStartYet:
                case ExternalTaskStatusType.ReadyForSubmission:
                case ExternalTaskStatusType.NotStarted:
                    return 'info';

                case ExternalTaskStatusType.Submitted:
                    return 'primary';

                case ExternalTaskStatusType.Confirmed:
                    return 'success';
                default:
                    break;
            }
        }
        if (
            this.toDoService.isDraft(activity) &&
            this.toDoService.isEmail(activity)
        ) {
            return 'info';
        }

        if (this.toDoService.isCompleteActivity(activity)) {
            return 'success';
        }

        if (this.toDoService.isCancelledActivity(activity)) {
            return 'info';
        }

        if (
            this.toDoService.isAllDay(activity) &&
            this.toDoService.isCompleteActivity(activity) &&
            this.toDoService.isEmail(activity)
        ) {
            return 'success';
        }

        if (
            this.toDoService.isOverdueActivity(activity) ||
            this.toDoService.isEndedActivity(activity)
        ) {
            return 'danger';
        }

        return 'info';
    }

    getLabel(activity: Activity | ActivityExternalTask): string {
        if ((activity as ActivityExternalTask).externalStatus) {
            switch ((activity as ActivityExternalTask).externalStatus) {
                case ExternalTaskStatusType.CannotStartYet:
                    return TRANSLATION_STATUS_TAG_CANNOT_START;
                case ExternalTaskStatusType.ReadyForSubmission:
                    return TRANSLATION_STATUS_TAG_READY;
                case ExternalTaskStatusType.NotStarted:
                    return TRANSLATION_STATUS_TAG_NOT_STARTED;

                case ExternalTaskStatusType.Submitted:
                    return TRANSLATION_STATUS_TAG_SUBMITTED;

                case ExternalTaskStatusType.Confirmed:
                    return TRANSLATION_STATUS_TAG_CONFIRMED;
                default:
                    break;
            }
        }
        if (
            this.toDoService.isDraft(activity) &&
            this.toDoService.isEmail(activity)
        ) {
            return TRANSLATION_STATUS_TAG_DRAFT;
        }

        if (
            this.toDoService.isAllDay(activity) &&
            this.toDoService.isCompleteActivity(activity) &&
            this.toDoService.isEmail(activity)
        ) {
            return TRANSLATION_STATUS_TAG_SENT;
        }

        if (this.toDoService.isEmail(activity)) {
            return TRANSLATION_STATUS_TAG_RECEIVED;
        }
        if (this.toDoService.isCancelledActivity(activity)) {
            return TRANSLATION_STATUS_TAG_CANCELLED;
        }
        if (this.toDoService.isCompleteActivity(activity)) {
            return TRANSLATION_STATUS_TAG_COMPLETE;
        }
        if (this.toDoService.isEndedActivity(activity)) {
            return TRANSLATION_STATUS_TAG_ENDED;
        }
        if (this.toDoService.isOverdueActivity(activity)) {
            return TRANSLATION_STATUS_TAG_OVERDUE;
        }
        return TRANSLATION_STATUS_TAG_OPEN;
    }

    constructor(private toDoService: ToDoService) {}
}
